import * as React from "react"
import LogoImg from "../images/logo_towers_of_thana.png"

interface Props {
  useSmallHeader?: boolean;
}

const Header: React.FC<Props> = ({ useSmallHeader = false }) => {
  return (
    <div className={`header column column--center ${useSmallHeader ? "header__compact" : ""}`}>
      <div className="header__background"></div>
      <h1>
        <a href="/">
          <img className="header__logo" src={LogoImg} alt="towers-of-thana-logo"></img>
        </a>
      </h1>
      <p className="header__release-date text">Available Now!</p>
    </div>
  )
};

export default Header;