import "../css/index.sass"
import * as React from "react"

import Header from "../components/header"
import Footer from "../components/footer"
import Subscribe from "../components/subscribe"
import AnalyticsConfirm from "../components/analyticsConfirm"
import SEO from "../components/seo"
import PageTitle from "../components/pageTitle"


const IndexPage = () => {
  return (
    <main>
      <PageTitle title="Subscribe" />
      <Header useSmallHeader={true} />
      <Subscribe />
      {/* <AnalyticsConfirm /> */}
      <Footer />
    </main>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)