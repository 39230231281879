// import * as React from "react"
import React, { useState } from "react"
import VioImg from "../images/vio.png"
import Analytics from "../utils/analytics";

const ENDPOINT: string = "https://ftil7al4bi.execute-api.us-east-1.amazonaws.com/prod/subscribe";

var _showSpinner: boolean;
var _showErrorMsg: boolean;
var _buttonClass: string;
var _buttonText: string;

var _canSendRequest = true;
var _triedToSendOnce = false; //Don't want to validate email unless we pressed the button at least once

const Subscribe = () => {
  const [_showSpinner, setShowSpinner] = useState(false);
  const [_buttonClass, setButtonClass] = useState("");
  const [_buttonId, setButtonId] = useState("subscription-button");
  const [_buttonText, setButtonText] = useState("subscribe");

  async function handleSubmit() {
    try {
      if (!_canSendRequest)
        return;
      
      _triedToSendOnce = true;
      if (!validateInput())
        return;

      setShowSpinner(true);
      const options = getOptions();
      const resp = await fetch(ENDPOINT, options);
      setShowSpinner(false);
      _canSendRequest = false;

      if (resp.status !== 200) {
        const respJson = await resp.json();
        var errorMsg = `Status: ${resp.status} - Error: ${respJson.status} Message: ${respJson.message}`;
        console.error(errorMsg);

        if (respJson.status == "ALREADY_SUBSCRIBED") {
          showError("Already subscribed");
          sendAnalyticsError("Already subscribed");
        } else if (respJson.status == "INVALID_EMAIL") {
          showError("Invalid email");
          sendAnalyticsError("Invalid email");
        } else {
          showError("Whoops! Something went wrong");
          sendAnalyticsError("Unknown error", errorMsg);
        }
        
        return;
      } else {
        const jsonData = await resp.json();
        console.log("Json: " + JSON.stringify(jsonData));
        setButtonText("Subscribed!");
        setButtonClass("subscribe__button__success subscribe__button__disabled");
        setButtonId("subscription-button-success");
        sendAnalyticsSuccess();
      }

    } catch (error: any) {
      showError("Whoops! Something went wrong.");
      let message = 'Unknown Error'
      if (error != null && !isNullOrEmpty(error.message)) 
        message = error.message;

      console.error(`An error occured: ${message}`);
      sendAnalyticsError("Unknown error", message);
    }    
  };

  function handleNameChange(): void {
    setDefault();
  }

  function handleEmailChange(): void {
    if (!_triedToSendOnce)
      return;

    if (isEmailValid())
      setDefault();
    else
      showError("Invalid email")
  }

  function isEmailValid(): boolean {
    const email = (document.getElementById('email') as HTMLInputElement)?.value;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  }

  function sendAnalyticsError(status: string, errorDetails?: string): void {
    var obj: DataEvent = {
      event: "subscription-error",
      status: status
    };

    if (errorDetails != null)
      obj.errorDetails = errorDetails;

    Analytics.sendEventObject(obj);
  }

  function sendAnalyticsSuccess(): void {
    Analytics.sendEventString("subscription-success");
  }

  function validateInput(): boolean {
    const name = (document.getElementById('name') as HTMLInputElement)?.value;
    const email = (document.getElementById('email') as HTMLInputElement)?.value;

    if (name == null || name.length < 3) {
      showError("Invalid username");
      sendAnalyticsError("Invalid username");
      return false;
    } 
    
    if (email == null || !isEmailValid()) {
      showError("Invalid email")
      sendAnalyticsError("Invalid email");
      return false;
    }

    return true;
  }

  function setDefault(): void {
    _canSendRequest = true;

    setShowSpinner(false);
    setButtonClass("");
    setButtonText("subscribe");
    setButtonId("subscription-button")
  }

  function showError (message: string): void {
    _canSendRequest = false;
    setShowSpinner(false);

    setButtonClass("subscribe__button__error subscribe__button__disabled");
    setButtonId("subscription-button-failure");
    setButtonText(message);
  }
  
  function isNullOrEmpty(s: string): boolean {
    if (s == null || s.trim().length == 0)
      return true;
    return false;
  }

  function getOptions(): RequestInit {
    // Get the form element
    const name = (document.getElementById('name') as HTMLInputElement)?.value;
    const email = (document.getElementById('email') as HTMLInputElement)?.value;

    const data = { 
      firstName: name,
      email: email
    };
    
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    return options;
  }
  
  return (
    <div className="section section--subscribe">
      <div className="row row--center">
        <div className="subscribe__container">
          {/* <div className="subscribe__content"> */}
            <p className="text subscribe__title">
              {/* Join our mailing list */}
              SUBSCRIBE NOW!
            </p>
            <p className="text subscribe__text">{
              // "Get all the major announcements from Bishop Games and our projects, as well as exclusive goodies and perks, straight in your inbox by subscribing to this newsletter!"
              "Join the newsletter and get access, news, goodies and perks, straight in your inbox."
            }</p>
            <form className="subscribe__form">
              <label className="subscribe__input" onChange={handleNameChange} >
                <input type="text" id="name" placeholder="First Name" tabIndex={100} />
              </label>
              <label className="subscribe__input" onChange={handleEmailChange} >
                <input type="email" id="email" placeholder="Email" tabIndex={101} />
              </label>
              {!_showSpinner && (
                <div className={`subscribe__button ${_buttonClass}`} id={_buttonId}>
                  <a className="text" onClick={handleSubmit} tabIndex={102}>
                    {_buttonText}
                  </a>
                </div>
              )}
              {_showSpinner && (
                <div className="subscribe__spinning-icon">
                  <div className="subscribe__circle"></div>
                  <div className="subscribe__circleContent"></div>
                </div>
              )}
            </form>
          {/* </div> */}
          {/* <div className="subscribe__imgContainer">
            <img className="subscribe__img" src={VioImg}></img>
          </div> */}
        </div>
      </div>
    </div>
  )
};

export default Subscribe;