import React from "react"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

interface Props {
  title?: string;
  description?: string;
  pathname?: string;
  children?: JSX.Element,
}
const SEO: React.FC<Props> = ({ title, description, pathname, children }) => {
  const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername, video } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
    video
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {/* This section showcases a video card when the website is embeded in twitter
        <meta name="twitter:card" content="player" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:site" content={seo.twitterUsername} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:player" content={seo.video} />
        <meta name="twitter:player:width" content="1280" />
        <meta name="twitter:player:height" content="720" />
        <meta name="twitter:image" content={seo.image} />
      */}
      {/* Section pour twitter, mais avec un summary large image au lieu d'une vidéo */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={seo.twitterUsername} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      {/* <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" /> */}
      {children}
    </>
  )
}

export default SEO;