import * as React from "react"
import Fb from "../images/icon_facebook.svg"
import Twitter from "../images/icon_twitter.svg"
import Discord from "../images/icon_discord.svg"
import Instagram from "../images/icon_instagram.svg"
import Youtube from "../images/icon_youtube.svg"
import ArrowImg from "../images/icon_arrow_down.svg"
import BishopLogo from "../images/logo_bishop.svg"

class SocialMedia {
  public id: string = "";
  public text: string = "";
  public icon: any = null;
  public link: string = "";
  public alt: string = "";
}

const socialMedias: SocialMedia[] = [
  {
    id: "facebook",
    text: "Facebook",
    icon: Fb,
    link: "https://www.facebook.com/bishopgames/",
    alt: "facebook-icon"
  },
  {
    id: "twitter",
    text: "Twitter",
    icon: Twitter,
    link: "https://twitter.com/towersofthana",
    alt: "twitter-icon"
  },
  {
    id: "discord",
    text: "Discord",
    icon: Discord,
    link: "https://discord.gg/pWBeGScjm7",
    alt: "discord-icon"
  },
  {
    id: "instagram",
    text: "Instagram",
    icon: Instagram,
    link: "https://www.instagram.com/bishopgamesstudio",
    alt: "instagram-icon"
  },
  {
    id: "youtube",
    text: "Youtube",
    icon: Youtube,
    link: "https://www.youtube.com/c/BishopGamesStudio",
    alt: "youtube-icon"
  }
];

const Footer = () => (
  <div className="footer">
    <div className="footer__background"></div>
    <div className="row row--to-column-tablet">
      <div className="footer__press-kit flex">
        <h2 className="heading heading--h2 heading--gold">
          Press kit
        </h2>
        <p className="text">
          If you’re looking for all the official assets please
          click the link below. For anything else, <a href="https://bishopgames.com/contact" className="link">contact us</a>.
        </p>
        <div className="footer__download">
          <a href="http://presskit.towersofthana.com" id="footer-presskit-button" className="link link--cta link--press-kit">
            Go to press kit
            <img className="footer__download-icon link__icon link__icon--right" src={ArrowImg}></img>
          </a>
        </div>
      </div>
      <div className="footer__social-media flex">
        <h2 className="heading heading--h2 heading--gold">
          Social media
        </h2>
        <p className="text">
          To stay in touch with our latest updates and to know more about our games, follow us on social media.
        </p>
        <div className="footer__social-media-links row">
          {socialMedias.map(media => 
            <a href={media.link} title={media.text}  id={"footer-"+media.id+"-button"} className="social-media" key={`social-media-${media.id}`}>
             <img src={media.icon} alt={media.alt}></img> 
            </a>
          )}
        </div>
      </div>
    </div>
    <div className="footer__banner">
      <p className="text text--gold text--small">
        ©Bishop Games Studio inc. 2023 - All Rights Reserved
      </p>
      <div className="footer__logo-container">
        <div className="footer__logo-background"></div>
        <img className="footer__logo" src={BishopLogo} alt="bishop-games-logo"></img>
      </div>
    </div>
  </div>
);

export default Footer;